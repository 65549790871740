import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { useCurrentLang } from 'hooks/useCurrentLang';

import { AllSitePageResult, UseLanguageSwitchHook } from './models';

export const useLanguageSwitch: UseLanguageSwitchHook = () => {
  const {
    languages,
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const { pathname } = useLocation();

  const {
    allSitePage: { nodes },
  }: AllSitePageResult = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          id
          path
          context {
            uid
            locale
          }
        }
      }
    }
  `);

  const currentPage = useMemo(
    () => nodes.find(({ path }) => path === pathname) || null,
    [nodes, pathname]
  );

  const list = useMemo(() => {
    const filteredNodes = nodes.filter((node) => node.context?.uid === currentPage?.context.uid);

    return languages.map(([locale, url]) => {
      const findNode = filteredNodes.find((node) => node.context?.locale === locale);

      if (findNode) {
        return {
          currentPage: currentLangCode === findNode.context?.locale,
          locale: findNode.context?.locale,
          url: findNode.path,
        };
      }

      return {
        currentPage: currentLangCode === locale,
        locale,
        url,
      };
    });
  }, [currentLangCode, currentPage, languages, nodes]);

  return { list, currentPage };
};
