import React, { FC } from 'react';
import { Footer } from '@design-system/footer';
import { GlobalHeader as Header } from '@design-system/global-header';
import loadable from '@loadable/component';
import classNames from 'classnames';
import { useLanguageSwitch } from 'hooks/useLanguageSwitch';
import { footerMapper } from 'mappers/footer';
import { headerMapper } from 'mappers/header';

import 'normalize.css';
import 'styles/main.scss';

import Seo from 'common/Seo';
import StructuredPageSchema from 'common/StructuredPageSchema';
import Loadable from 'components/Loadable';
import { schemaService } from 'utils/schemaService';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

export const Layout: FC<LayoutProps> = ({
  children,
  className,
  seo,
  url,
  header,
  footer,
  pageUid,
  additionalSchema,
  schemaImageUrl,
  settings,
}) => {
  const CSSclassNames = classNames('layout', className);

  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  const languagesSwitch = useLanguageSwitch();

  const schemaData = schemaService.extractSchemaData({
    lang: settings?.locale,
    pagePathname: seo?.canonical_url.href,
    siteUrl: settings?.site_url,
    siteName: settings?.site_name,
    brandLogo: header?.logo?.large_image?.gatsbyImageData?.images?.fallback?.src,
    brandName: settings?.brand_name,
    socialNetworks: footer?.social_links?.[0]?.links,
    schemaImageUrl,
    seo,
  });

  return (
    <div className={CSSclassNames}>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      {seo ? <Seo {...{ seo, urls: languagesSwitch.list, pageUid, url }} /> : null}
      <StructuredPageSchema additionalSchema={additionalSchema} schemaData={schemaData} />
      {header ? <Header {...headerMapper(header)} /> : null}

      <main id="main" className="layout__main">
        {children}
      </main>

      {footer ? <Footer {...footerMapper(footer)} className="footer" /> : null}
    </div>
  );
};
