import { PageCardProps } from '@design-system/page-card';
import { ctaMapper } from 'mappers/ctaBlock';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';
import { ItemListingPageCard, ItemListingRelatedPageCard } from 'mappers/itemListing/models';
import { linkMapper } from 'mappers/link';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { PageCard, RelatedPageCard } from './models';

export const relatedPageCardMapper = (
  { heading, image, label, fullPath }: RelatedPageCard,
  {
    image_settings: imageSettings,
    card_content_ui: headingUi,
    variant,
    hide_labels,
  }: Omit<ItemListingRelatedPageCard, 'type' | '__typename'>
): PageCardProps =>
  ({
    ...(image && {
      cardMedia: { image: imageMapper({ ...image, ...imageSettings }) },
    }),
    link: { component: TMPLinkWrap, props: { href: fullPath } },
    cardContent: {
      headingBlock: headingBlockMapper({ ...heading, ui: headingUi }),
    },
    ...(!hide_labels &&
      label[0]?.content && {
        cardLabel: {
          labelContent: label[0].content,
          srOnlyLabelPrefix: label[0].sr_prefix,
          labelVariant: label[0].variant,
          labelSize: label[0].size,
        },
      }),
    variant,
  } as PageCardProps);

export const pageCardMapper = (
  { card_content: { heading, disclaimer }, cta_blocks, image, label, link }: PageCard,
  {
    image_settings: imageSettings,
    align,
    mode,
    variant,
    horizontal_direction,
  }: Omit<ItemListingPageCard, 'type' | '__typename'>
): PageCardProps =>
  ({
    ...(image && {
      cardMedia: { image: imageMapper({ ...image, ...imageSettings }) },
    }),
    ...(link.length && { link: { component: TMPLinkWrap, props: linkMapper(link[0]) } }),
    cardContent: {
      headingBlock: headingBlockMapper(heading),
      disclaimer,
    },
    ...(label[0]?.content && {
      cardLabel: {
        labelContent: label[0].content,
        srOnlyLabelPrefix: label[0].sr_prefix,
        labelVariant: label[0].variant,
        labelSize: label[0].size,
      },
    }),
    align,
    mode,
    variant,
    horizontal_direction,
    ...(cta_blocks.length && { cardCta: { ctaBlock: { data: ctaMapper(cta_blocks[0]) } } }),
  } as PageCardProps);
