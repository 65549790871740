import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { PageFields, PageHeader } from './models';

export const breadcrumbsMapper = (items: PageFields['breadcrumbs']) =>
  // TODO: check why items are null - this should not happen in general
  items
    ? items.map(({ fullPath, title }) => ({
        href: fullPath,
        children: title,
      }))
    : [];

export const pageHeaderMapper = ({
  page_header,
  fields,
}: {
  page_header: PageHeader;
  fields: PageFields;
}) => {
  const {
    heading_block,
    image_element,
    mode,
    variant,
    breadcrumbs: [
      { hide_last_item: hideLastItem, navigation_name: navigationName, overflow_button },
    ],
  } = page_header;

  return {
    headingBlock: headingBlockMapper(heading_block),
    ...(image_element.length > 0 && { imageElement: imageMapper(image_element[0], true) }),
    breadcrumbs: {
      navigationName,
      hideLastItem,
      items: breadcrumbsMapper(fields.breadcrumbs),
      overflowBtn: {
        children: overflow_button,
      },
      linkComponent: TMPLinkWrap,
    },
    mode,
    variant,
  };
};
