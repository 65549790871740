import { TeaserProps } from '@design-system/teaser';
import { ctaBlockMapper } from 'mappers/ctaBlock';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';

import { Teaser } from './models';

export const teaserMapper = ({
  variant,
  background_image: [backgroundImage],
  heading,
  cta_blocks: { cta_blocks: ctaBlocks },
  disclaimer,
  foreground_image: [foregroundImage],
  tag,
  mode,
  size,
  width,
}: Teaser): TeaserProps =>
  ({
    variant,
    backgroundImage: imageMapper(backgroundImage),
    headingBlock: { props: headingBlockMapper(heading) },
    ...(ctaBlocks.length && { ctaBlock: { props: ctaBlockMapper(ctaBlocks) } }),
    ...(disclaimer && { disclaimer }),
    foregroundImage: imageMapper(foregroundImage),
    tag,
    mode,
    ...(size && { size }),
    ...(width && { width }),
  } as TeaserProps);
