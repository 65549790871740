import React, { FC } from 'react';
import loadable from '@loadable/component';
import { brandStatementBannerMapper } from 'mappers/brandStatementBanner';
import { contentBlockMapper } from 'mappers/contentBlock';
import { imageBlockMapper } from 'mappers/imageBlock';
import { imagePlusTextBlockMapper } from 'mappers/imagePlusTextBlock';
import { itemListingMapper } from 'mappers/itemListing';
import { ldsBlockMapper } from 'mappers/ldsBlock';
import { questionAnswerMapper } from 'mappers/questionAnswer';
import { salsifyColumnsTextMapper } from 'mappers/salsifyColumnsText';
import { salsifyTextMapper } from 'mappers/salsifyText';
import { teaserMapper } from 'mappers/teaser';
import { typographyMapper } from 'mappers/typography';

import { BodyRenderProps } from './models';

const SalsifyColumnsText = loadable(() => import('components/SalsifyColumnsText'));
const ItemListing = loadable(() => import('components/ItemListing'));
const Teaser = loadable(() => import('@design-system/teaser'), {
  resolveComponent: (components) => components.Teaser,
});
const PageBlock = loadable(() => import('@design-system/page-block'), {
  resolveComponent: (components) => components.PageBlock,
});
const ContentBlock = loadable(() => import('components/ContentBlock'));
const Typography = loadable(() => import('@design-system/typography'), {
  resolveComponent: (components) => components.Typography,
});
const ImageBlock = loadable(() => import('components/ImageBlock'));
const ImagePlusTextBlock = loadable(() => import('@design-system/image-plus-text'), {
  resolveComponent: (components) => components.ImagePlusText,
});
const LdsBlock = loadable(() => import('components/LdsBlock'));
const DebugComponent = loadable(() => import('./DebugComponent'));
const QuestionAnswerList = loadable(() => import('components/QuestionAnswerList'));
const SitemapList = loadable(() => import('components/SitemapList'));

const typesMapper = {
  default: (typeName) => [
    (item) =>
      process.env.NODE_ENV === 'development' ? (
        <DebugComponent typeName={typeName} {...item} />
      ) : null,
    (item) => item,
  ],
  ItemListing: [ItemListing, itemListingMapper],
  Teaser: [Teaser, teaserMapper],
  BrandStatementBanner: [Teaser, brandStatementBannerMapper],
  ContentBlock: [ContentBlock, contentBlockMapper],
  TextBlock: [Typography, ({ typography }) => typographyMapper(typography)],
  SalsifyText: [PageBlock, salsifyTextMapper],
  SalsifyColumnsText: [SalsifyColumnsText, salsifyColumnsTextMapper],
  ImageBlock: [ImageBlock, imageBlockMapper],
  QuestionAnswerList: [QuestionAnswerList, questionAnswerMapper],
  ImagePlusTextBlock: [ImagePlusTextBlock, imagePlusTextBlockMapper],
  LdsBlock: [LdsBlock, ldsBlockMapper],
  SitemapBlock: [SitemapList],
};

const BodyRender: FC<BodyRenderProps> = ({ items, currentPageUid, externalData }) => {
  const renderItems = items.map((item) => {
    const value = Object.values(item).find((val) => val);

    return value || { typeName: 'Unknown component', item: [] };
  });

  return (
    <>
      {renderItems.map(({ typeName, item }, index) => {
        const [ItemComponent, itemMapper] = typesMapper[typeName] || typesMapper.default(typeName);

        const key = `${typeName}__${index}`;
        const value = Array.isArray(item) ? item[0] : item;

        return (
          <ItemComponent
            key={key}
            {...(itemMapper ? itemMapper(value, currentPageUid, externalData) : value)}
          />
        );
      })}
    </>
  );
};

export default BodyRender;
