import { FooterProps } from '@design-system/footer';
import { linkMapper } from 'mappers/link';
import { typographyMapper } from 'mappers/typography';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { Footer } from './models';

export const footerMapper = ({
  navigation,
  social_links: socialLinks,
  legal,
  disclaimer,
  mode,
}: Footer): FooterProps => ({
  navigationData: navigation.map(linkMapper),
  iconsData: socialLinks[0]?.links.map(linkMapper),
  copyright: legal,
  iconsHeading: socialLinks[0]?.header
    ? typographyMapper({
        ...socialLinks[0].header,
        className: socialLinks[0]?.hide_header ? 'hidden' : null,
      })
    : undefined,
  mode,
  disclaimer,
  LinkNavElement: TMPLinkWrap,
});
