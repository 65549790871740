import { TypographyProps } from '@design-system/typography';
import { optionalLinkMapper } from 'mappers/link';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { Typography } from './models';

export const typographyMapper = ({
  content,
  tag,
  variant,
  link,
  align,
  wrapping,
  className,
}: Typography) => {
  const linkProps = optionalLinkMapper(link);

  return {
    content,
    tag,
    variant,
    ...(linkProps ? { link: { component: TMPLinkWrap, props: linkProps } } : undefined),
    align,
    wrapping,
    className,
  } as TypographyProps;
};
