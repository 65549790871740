import React from 'react';
import { AssetItem } from '@design-system/asset-item';
import { PageCard } from '@design-system/page-card';
import { PictoItem } from '@design-system/picto-item';
import { ProductCard } from '@design-system/product-card';
import { assetItemMapper } from 'mappers/assetItem';
import { AssetItem as AssetItemType } from 'mappers/assetItem/models';
import { carouselMapper } from 'mappers/carousel';
import { fluidGridMapper } from 'mappers/fluidGrid';
import { pageBlockMapper } from 'mappers/pageBlock';
import { pageCardMapper, relatedPageCardMapper } from 'mappers/pageCard';
import {
  PageCard as PageCardType,
  RelatedPageCard,
  RelatedPageCardBase,
} from 'mappers/pageCard/models';
import { pictoItemMapper } from 'mappers/pictoItem';
import { PictoItem as PictoItemType } from 'mappers/pictoItem/models';
import { productCardCategoryMapper, productCardRefMapper } from 'mappers/productCard';
import { ProductCardCategory, ProductCardRef } from 'mappers/productCard/models';

import { ItemListingCarouselProps, ItemListingProps } from 'components/ItemListing/models';

import {
  ItemListing,
  ItemListingCarousel,
  ItemListingPageCard,
  ItemListingProductCardCategory,
  ItemListingProductCardRef,
  ItemListingRelatedPageCard,
  ItemListingTypes,
  ItemTypes,
} from './models';

const checkAsRelatedPage = (item: ItemTypes, currentPageUid: string): boolean =>
  (item as RelatedPageCardBase).relatedPageUid !== currentPageUid;

const checkAsProductRef = (item: ItemTypes): boolean => {
  const {
    fields: { fullPath, productData },
  } = item as ProductCardRef;

  return Boolean(fullPath && productData);
};

export const mapItems = (
  { items, __typename: type, ...itemsSettings }: ItemListingTypes,
  currentPageUid: string
) =>
  items
    .map((item) => {
      if (type.includes('asset_items') && 'image_settings' in itemsSettings)
        return (
          <AssetItem
            key={(item as AssetItemType).heading.headline as string}
            {...assetItemMapper(item as AssetItemType, itemsSettings.image_settings)}
          />
        );
      if (type.includes('picto_items'))
        return (
          <PictoItem
            key={(item as PictoItemType).heading.headline as string}
            {...pictoItemMapper(item as PictoItemType)}
          />
        );
      if (type.includes('page_cards_by_category'))
        return checkAsRelatedPage(item, currentPageUid) ? (
          <PageCard
            key={(item as RelatedPageCard).heading.headline as string}
            {...relatedPageCardMapper(
              item as RelatedPageCard,
              itemsSettings as Omit<ItemListingRelatedPageCard, 'type' | '__typename'>
            )}
          />
        ) : null;
      if (type.includes('page_cards'))
        return (
          <PageCard
            key={(item as PageCardType).card_content.heading.headline as string}
            {...pageCardMapper(
              item as PageCardType,
              itemsSettings as Omit<ItemListingPageCard, 'type' | '__typename'>
            )}
          />
        );
      if (type.includes('product_cards_by_category'))
        return checkAsRelatedPage(item, currentPageUid) ? (
          <ProductCard
            key={(item as ProductCardCategory).relatedPageUid as string}
            {...productCardCategoryMapper(
              item as ProductCardCategory,
              itemsSettings as Omit<ItemListingProductCardCategory, 'type' | '__typename'>
            )}
          />
        ) : null;
      if (type.includes('product_cards_by_ref'))
        return checkAsProductRef(item) ? (
          <ProductCard
            key={(item as ProductCardRef).uid as string}
            {...productCardRefMapper(
              item as ProductCardRef,
              itemsSettings as Omit<ItemListingProductCardRef, 'type' | '__typename'>
            )}
          />
        ) : null;

      throw new Error('Not supported type');
    })
    .filter((item) => item);

export const itemListingCarouselMapper = (
  { page_block, carousel: [carousel] }: ItemListingCarousel,
  reactItems
): ItemListingCarouselProps =>
  ({
    pageBlock: pageBlockMapper(page_block),
    items: reactItems,
    carousel: carouselMapper(carousel, reactItems),
  } as ItemListingCarouselProps);

export const itemListingMapper = (
  itemListing: ItemListing | ItemListingCarousel,
  currentPageUid: string
): ItemListingProps | ItemListingCarouselProps => {
  const {
    items: [itemsData],
  } = itemListing;
  const reactItems = mapItems(itemsData, currentPageUid);

  if ('carousel' in itemListing) {
    return itemListingCarouselMapper(itemListing as ItemListingCarousel, reactItems);
  }

  const {
    page_block,
    fluid_grid: [fluidGrid],
    type,
    load_more_button_text: loadMoreButtonText,
  } = itemListing;
  const listingItems = ['ul', 'ol'].includes(fluidGrid.ui.tag)
    ? // eslint-disable-next-line react/no-array-index-key
      reactItems.map((item, index) => <li key={index}>{item}</li>)
    : reactItems;

  return {
    pageBlock: pageBlockMapper(page_block),
    items: listingItems,
    type,
    fluidGrid: fluidGridMapper(fluidGrid),
    loadMoreButtonText,
  } as ItemListingProps;
};
