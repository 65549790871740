import { FluidGridProps } from '@design-system/fluid-grid';

import { FluidGrid } from './models';

export const fluidGridMapper = ({
  ui: { cards_per_row: cardsPerRow, preferred_card_size: preferredCardSize, variant, tag },
}: FluidGrid): FluidGridProps =>
  ({
    ...(variant === 'fluid' ? { cardsPerRow } : undefined),
    preferredCardSize,
    variant,
    tag,
  } as FluidGridProps);
