import { BodyRenderExternalData } from 'components/BodyRender/models';
import { nlToBr } from 'utils/stringUtils/stringUtils';

import { QuestionAnswerItem } from './models';

export const questionAnswerMapper = (
  data: { list: QuestionAnswerItem[] },
  _uid: string,
  { salsifyExternalData }: BodyRenderExternalData
) => ({
  ...data,
  list: data.list
    .map(({ question, answer, disabled, item_ref: [item_ref] }) => ({
      question,
      disabled,
      answer:
        salsifyExternalData && item_ref?.salsify_description_field
          ? nlToBr(salsifyExternalData[item_ref.salsify_description_field])
          : answer,
    }))
    .filter(({ answer }) => answer),
});
