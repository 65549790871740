import { pageBlockMapper } from 'mappers/pageBlock';

import { ContentBlockProps } from 'components/ContentBlock/models';

import { ContentBlock } from './models';

export const contentBlockMapper = ({
  page_block: pageBlock,
  content,
  currentPageUid,
}: ContentBlock): ContentBlockProps => ({
  pageBlock: pageBlockMapper(pageBlock),
  content,
  currentPageUid,
});
