import { IconProps } from '@design-system/icon';
import { Icon } from 'types/common';

export const iconMapper = ({ icon }: Icon): IconProps => ({
  icon,
});

export const mapIconToProps = (icon: Icon[], iconPropName: string) => ({
  [iconPropName]:
    icon?.length > 0
      ? {
          props: iconMapper(icon[0]),
        }
      : undefined,
});
