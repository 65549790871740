import { LinkProps } from '@design-system/link';
import { mapIconToProps } from 'mappers/common';

import { Link, LinkBase } from './models';

export const linkBaseMapper = ({ link: [{ url, fields }], title }: LinkBase) => ({
  href: fields ? fields.fullPath : url,
  title,
});

export const linkMapper = ({
  link,
  size,
  variant,
  aria_label,
  icon_suffix: iconSuffix,
  icon_prefix: iconPrefix,
  title,
}: Link) => {
  const { href, title: baseTitle } = linkBaseMapper({ link, title });

  return {
    href,
    children: baseTitle,
    title: baseTitle,
    size: size || undefined,
    variant: variant || undefined,
    'aria-label': aria_label || undefined,
    ...mapIconToProps(iconSuffix, 'iconSuffix'),
    ...mapIconToProps(iconPrefix, 'iconPrefix'),
  } as LinkProps;
};

export const optionalLinkMapper = (link: Link[]) => {
  if (link.length > 0 && link[0].link.length) {
    return linkMapper(link[0]);
  }

  return null;
};
