import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import { NlToBrFunctionType, ToPascalCaseFunctionType } from './models';

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const nlToBr: NlToBrFunctionType = (str) => str?.replace(/\n/g, '<br />');

const stringUtils = {
  toPascalCase,
  nlToBr,
};

export default stringUtils;
