import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GlobalHeaderProps } from '@design-system/global-header';
import { navigationDataItem } from '@design-system/global-header/dist/cjs/components/navigation/models';
import {
  LogoDataProps,
  SkipLinkProps,
  UtilityLinksDataItem,
} from '@design-system/global-header/dist/cjs/models';
import { iconMapper } from 'mappers/common';
import { linkBaseMapper, linkMapper } from 'mappers/link';
import { LinkBase } from 'mappers/link/models';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { Header, Logo, MapHeaderItemsResult, NavigationLink, SkipLink } from './models';

const headerLogoMapping = ({
  small_image: smallImage,
  large_image: largeImage,
  link,
  alt,
}: Logo): LogoDataProps => {
  const smallGatsbyImage = getImage(smallImage);
  const largeGatsbyImage = getImage(largeImage);

  return {
    imageSmall: {
      element: smallGatsbyImage ? (
        <GatsbyImage image={smallGatsbyImage} alt={alt} loading="eager" />
      ) : undefined,
    },
    imageLarge: {
      element: largeGatsbyImage ? (
        <GatsbyImage image={largeGatsbyImage} alt={alt} loading="eager" />
      ) : undefined,
    },
    ...{ link: { component: TMPLinkWrap, props: linkMapper(link) } },
  };
};

const mapHeaderItems = (item: NavigationLink | LinkBase): MapHeaderItemsResult => {
  const { href, title: label } = linkBaseMapper(item);

  return {
    label,
    href,
    ...('submenu_label' in item && { subMenuLabel: item.submenu_label }),
    ...('submenu' in item && item.submenu.length && { subItems: item.submenu.map(mapHeaderItems) }),
  };
};

const skipLinkMapper = ({ mode, link }: SkipLink): SkipLinkProps => ({
  ...linkMapper(link),
  mode,
});

export const headerMapper = ({
  logo,
  burger: {
    open_icon: [burgerOpenIcon],
    close_icon: [burgerCloseIcon],
    toggle_label: burgerToggleLabel,
    close_label: burgerCloseLabel,
    open_label: burgerOpenLabel,
  },
  navigation,
  more_button: moreBtn,
  navigation_aria_label: navigationAriaLabel,
  navigation_close_icon: closeIcon,
  navigation_open_icon: openIcon,
  utility_links_name: utilityLinksName,
  utility_links,
  is_header_sticky: isHeaderSticky,
  mode,
  skip_link: skipLink,
}: Header): GlobalHeaderProps => ({
  mode,
  logo: headerLogoMapping(logo),
  burgerOpenIcon: iconMapper(burgerOpenIcon),
  burgerCloseIcon: iconMapper(burgerCloseIcon),
  burgerToggleLabel,
  burgerCloseLabel,
  burgerOpenLabel,
  navigationName: navigationAriaLabel,
  navigationData: navigation
    .map(mapHeaderItems)
    .filter((val) => Boolean(val)) as navigationDataItem[],
  activeUrlPath: typeof window !== 'undefined' ? window.location.pathname : '',
  utilityLinksName,
  ...(utility_links.length && {
    utilityLinksData: utility_links
      .map(mapHeaderItems)
      .filter((val) => Boolean(val)) as UtilityLinksDataItem[],
  }),
  moreBtn,
  ...(openIcon?.length && { openIcon: iconMapper(openIcon[0]) }),
  ...(closeIcon?.length && { closeIcon: iconMapper(closeIcon[0]) }),
  isHeaderSticky,
  skipLink: skipLinkMapper(skipLink),
  linkNavElement: TMPLinkWrap,
});
