import { CtaBlockProps } from '@design-system/cta-block';
import { buttonMapper } from 'mappers/button';
import { linkMapper } from 'mappers/link';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { ButtonPropsType, CTABlock, LinkPropsType } from './models';

export const ctaMapper = (block: CTABlock): LinkPropsType | ButtonPropsType => {
  if (block.link) {
    return {
      elementType: 'link',
      ...linkMapper(block.link),
    };
  }

  return {
    elementType: 'button',
    ...buttonMapper(block.button!),
  };
};

export const ctaBlockMapper = (blocks: CTABlock[]): CtaBlockProps => ({
  data: blocks.map(ctaMapper),
  LinkComponent: TMPLinkWrap,
});
