import { pageBlockUIMapper } from 'mappers/pageBlock';

import { LdsProps } from 'components/LdsBlock/models';

import { PageBlock } from './models';

export const ldsBlockMapper = ({
  fields: { ldsContent, ldsVersionId, ldsBusinessId },
  page_block_ui,
  lds_settings,
}: PageBlock): LdsProps => ({
  ldsContent,
  ldsVersionId,
  ldsBusinessId,
  pageBlockUI: pageBlockUIMapper(page_block_ui[0].ui),
  platform: lds_settings[0].platform,
  ot: lds_settings[0].ot,
});
