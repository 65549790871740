import { ReactNode } from 'react';
import { CarouselProps } from '@design-system/carousel';
import { buttonMapper } from 'mappers/button';

import { Carousel, FillLivePlaceholderModel } from './models';

const fillLivePlaceholder = (ariaLivePlaceholder: string, map: FillLivePlaceholderModel) => {
  let result = ariaLivePlaceholder;

  Object.entries(map).forEach(([before, after]) => {
    result = result.replace(`{${before}}`, after.toString());
  });

  return result;
};

export const carouselMapper = <T = CarouselProps>(
  {
    ui: {
      button_next: buttonNext,
      button_previous: buttonPrevious,
      gap,
      preferred_card_size: preferredCardSize,
      progress_indicator: progressIndicator,
      variant,
      aria_live_placeholder: ariaLivePlaceholder,
    },
  }: Carousel,
  items?: ReactNode[]
): T =>
  ({
    buttonNext: buttonMapper(buttonNext),
    buttonPrev: buttonMapper(buttonPrevious),
    variant,
    progressIndicator,
    ariaLivePlaceholder: (index: number, total: number, slidesPerView: number, progress: number) =>
      fillLivePlaceholder(ariaLivePlaceholder, { index, total, slidesPerView, progress }),
    ...(items && { items: items.map((children) => ({ children })) }),
    ...(gap !== null && { gap }),
    ...(preferredCardSize && { preferredCardSize }),
  } as T);
