import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { ISchemaData, schemaService, SchemaTypes } from 'utils/schemaService';

import { IStructuredPageSchemaProps } from './model';

const StructuredPageSchema: FC<IStructuredPageSchemaProps<ISchemaData>> = ({
  additionalSchema,
  schemaData,
}) => {
  const schemas: SchemaTypes[] = ['WebPage'];
  if (additionalSchema) {
    schemas.push(additionalSchema);
  }

  const structuredSchemaScripts = schemaService.getSchemas(schemas, schemaData);

  // Fix for bug in Helmet: it adds wrong structuredSchemaScripts
  // Add data-pageHref to filter heads in the onPreRenderHTML
  return (
    <Helmet>
      {structuredSchemaScripts?.map((item) => (
        <script data-pageHref={schemaData.pageHref} type={item.type}>
          {item.innerHTML}
        </script>
      ))}
    </Helmet>
  );
};

export default StructuredPageSchema;
