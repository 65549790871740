import { ButtonProps } from '@design-system/button';
import { iconMapper } from 'mappers/common';

import { Button } from './models';

export const buttonMapper = ({
  icon_prefix: iconPrefix,
  icon_suffix: iconSuffix,
  text,
  disabled,
  size,
  variant,
  aria_label,
}: Button): ButtonProps =>
  ({
    ...(iconPrefix.length && { iconPrefix: { props: iconMapper(iconPrefix[0]) } }),
    ...(iconSuffix.length && { iconSuffix: { props: iconMapper(iconSuffix[0]) } }),
    children: text,
    ...(disabled && { disabled }),
    ...(size && { size }),
    ...(variant && { variant }),
    ...(aria_label && { 'aria-label': aria_label }),
  } as ButtonProps);
