import { AssetItemProps } from '@design-system/asset-item';
import { ctaBlockMapper } from 'mappers/ctaBlock';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';
import { ImageConfig } from 'mappers/image/models';

import { AssetItem } from './models';

export const assetItemMapper = (
  { heading, align, tag, cta_blocks: ctaBlocks, image, variant }: AssetItem,
  imageConfig: ImageConfig
): AssetItemProps =>
  ({
    headingBlock: headingBlockMapper(heading),
    mediaVariant: variant,
    // TODO: consider handle image as GatsbyImage
    image: imageMapper({ ...image, ...imageConfig }),
    ...(align && { align }),
    ...(tag && { tag }),
    ...(ctaBlocks.length && { ctaBlock: ctaBlockMapper(ctaBlocks) }),
  } as AssetItemProps);
