import React from 'react';
import { GatsbyImage, GatsbyImageProps, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import { Image } from './models';

export const imageMapperRaw = (props: Image) => {
  const {
    image,
    rounded,
    aspect_ratio: aspectRatio,
    object_fit: objectFit,
    object_position: objectPosition,
    alt,
  } = props || {};

  if (image !== null && 'url' in image) {
    return {
      aspectRatio,
      objectFit,
      objectPosition,
      rounded,
      src: image?.url,
      alt,
    };
  }

  return undefined;
};

export const gatsbyImageMapper = (
  {
    image,
    rounded,
    aspect_ratio: aspectRatio,
    object_fit: objectFit,
    object_position: objectPosition,
    alt,
    ...htmlProperties
  }: Image,
  eagerLoad?: boolean
): JSX.Element | null => {
  const gatsbyImage = getImage(image);

  if (!gatsbyImage) {
    return null;
  }

  const className = classNames('cds-image', {
    [`cds-image--${objectFit}`]: objectFit,
    [`cds-image--rounded`]: rounded,
  });

  return (
    <GatsbyImage
      image={gatsbyImage}
      alt={alt}
      className={className}
      imgClassName={className}
      style={{
        aspectRatio: aspectRatio as string,
      }}
      objectFit={objectFit as GatsbyImageProps['objectFit']}
      objectPosition={objectPosition as GatsbyImageProps['objectPosition']}
      {...{ ...htmlProperties, ...(eagerLoad ? { loading: 'eager' } : undefined) }}
    />
  );
};

export const imageMapper = (props: Image, eagerLoad?: boolean) => {
  if (!props) {
    return undefined;
  }

  if (props.image !== null && 'gatsbyImageData' in props.image && props.image.gatsbyImageData) {
    return {
      element: gatsbyImageMapper(props, eagerLoad),
    };
  }

  return {
    props: {
      ...imageMapperRaw(props),
    },
  };
};
