import { fluidGridMapper } from 'mappers/fluidGrid';
import { pageBlockUIMapper } from 'mappers/pageBlock';
import { salsifyTextMapper } from 'mappers/salsifyText';

import { BodyRenderExternalData } from 'components/BodyRender/models';
import { SalsifyColumnsTextProps } from 'components/SalsifyColumnsText/models';

import { SalsifyColumnsText } from './models';

export const salsifyColumnsTextMapper = (
  { salsify_text_columns, fluid_grid_ui, page_block_ui }: SalsifyColumnsText,
  uid: string,
  { salsifyExternalData }: BodyRenderExternalData
): SalsifyColumnsTextProps => {
  if (!salsifyExternalData) {
    throw new Error('No salsify data');
  }

  return {
    salsifyTextColumns: salsify_text_columns.map((column) =>
      salsifyTextMapper(column, uid, { salsifyExternalData })
    ),
    pageBlock: pageBlockUIMapper(page_block_ui[0].ui),
    fluidGrid: fluidGridMapper(fluid_grid_ui[0]),
  } as SalsifyColumnsTextProps;
};
