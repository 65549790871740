import { HeadingBlockProps } from '@design-system/heading-block';
import { optionalLinkMapper } from 'mappers/link';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { HeadingBlock } from './models';

export const headingBlockMapper = ({
  ui: [ui],
  description,
  description_rte,
  headline,
  link,
}: HeadingBlock): HeadingBlockProps => {
  const mappedLinks = optionalLinkMapper(link);

  const {
    ui: {
      align,
      description_variant: descriptionVariant,
      headline_tag: headlineTag,
      headline_variant: headlineVariant,
      wrapping,
    },
  } = ui || { ui: {} };

  return {
    ...(align && { align }),
    ...(description_rte ? { description: description_rte } : description && { description }),
    ...(descriptionVariant && { descriptionVariant }),
    ...(headline && { headline }),
    ...(headlineTag && { headlineTag }),
    ...(headlineVariant && { headlineVariant }),
    ...(wrapping && { wrapping }),
    ...(mappedLinks && { headlineLink: { component: TMPLinkWrap, props: mappedLinks } }),
  } as HeadingBlockProps;
};
