import { PageBlockProps } from '@design-system/page-block';
import { headingBlockMapper } from 'mappers/headingBlock';

import { BodyRenderExternalData } from 'components/BodyRender/models';
import { nlToBr } from 'utils/stringUtils/stringUtils';

import { SalsifyText } from './models';

export const salsifyTextMapper = (
  {
    salsify_description_field,
    salsify_headline_field,
    custom_headline,
    page_block_ui: [
      {
        ui: { disableGutters, element, heading_ui, maxWidth, mode },
      },
    ],
  }: SalsifyText,
  _uid: string,
  { salsifyExternalData }: BodyRenderExternalData
): PageBlockProps => {
  if (!salsifyExternalData) {
    throw new Error('No salsify data');
  }

  return {
    disableGutters,
    element,
    heading: headingBlockMapper({
      ui: heading_ui,
      description: null,
      description_rte: salsify_description_field
        ? nlToBr(salsifyExternalData[salsify_description_field])
        : null,
      headline: salsify_headline_field
        ? salsifyExternalData[salsify_headline_field]
        : custom_headline,
      link: [],
    }),
    maxWidth,
    mode,
    className: 'salsify-text-block',
  } as PageBlockProps;
};
