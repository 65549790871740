import { TeaserProps } from '@design-system/teaser';
import { ctaBlockMapper } from 'mappers/ctaBlock';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';

import { BrandStatementBanner } from './models';

export const brandStatementBannerMapper = ({
  heading,
  cta_blocks: { cta_blocks: ctaBlocks },
  foreground_image: [foregroundImage],
}: BrandStatementBanner): TeaserProps =>
  ({
    variant: 'full-image',
    className: 'brand-statement-banner',
    headingBlock: { props: headingBlockMapper(heading) },
    ...(ctaBlocks.length && { ctaBlock: { props: ctaBlockMapper(ctaBlocks) } }),
    foregroundImage: imageMapper(foregroundImage),
  } as TeaserProps);
