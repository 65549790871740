import ContentstackLivePreview from '@contentstack/live-preview-utils';
import Contentstack from 'contentstack';

const { CONTENTSTACK_API_HOST = 'eu-api.contentstack.com' } = process.env;
const { CONTENTSTACK_APP_HOST = 'eu-app.contentstack.com' } = process.env;

const config: Contentstack.Config = {
  api_key: process.env.CONTENTSTACK_API_KEY as string,
  delivery_token: process.env.CONTENTSTACK_DELIVERY_TOKEN as string,
  environment: process.env.CONTENTSTACK_ENVIRONMENT as string,
  live_preview: {
    management_token: process.env.CONTENTSTACK_MANAGEMENT_TOKEN as string,
    enable: true,
    host: CONTENTSTACK_API_HOST as string,
  },
};

const Stack = Contentstack.Stack(config);

if (CONTENTSTACK_API_HOST) {
  Stack.setHost(CONTENTSTACK_API_HOST);
}

ContentstackLivePreview.init({
  enable: process.env.GATSBY_CONTENTSTACK_LIVE_PREVIEW === 'true',
  // @ts-expect-error: TS2739
  stackSdk: Stack,
  clientUrlParams: {
    host: CONTENTSTACK_APP_HOST,
  },
  ssr: false,
});

export const { onEntryChange } = ContentstackLivePreview;

export { Stack };
