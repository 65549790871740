import { PictoItemProps } from '@design-system/picto-item';
import { iconMapper } from 'mappers/common';
import { ctaBlockMapper } from 'mappers/ctaBlock';
import { headingBlockMapper } from 'mappers/headingBlock';

import { PictoItem } from './models';

export const pictoItemMapper = ({
  heading,
  icon,
  align,
  direction,
  tag,
  cta_blocks: ctaBlocks,
}: PictoItem): PictoItemProps =>
  ({
    headingBlock: headingBlockMapper(heading),
    ...(icon.length && { icon: iconMapper(icon[0]) }),
    ...(align && { align }),
    ...(direction && { direction }),
    ...(tag && { tag }),
    ...(ctaBlocks.length && { ctaBlock: ctaBlockMapper(ctaBlocks) }),
  } as PictoItemProps);
