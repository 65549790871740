import React, { useEffect, useState } from 'react';
import camelCase from 'lodash/camelCase';

import { onEntryChange, Stack } from './Stack';

type WithContentstackPreviewProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  pageContext: { uid: string; locale: string };
};

const withContentstackPreview =
  <T extends WithContentstackPreviewProps>(WrappedComponent: React.FC<T>, contentType: string) =>
  (props: T) => {
    const {
      data,
      pageContext: { uid, locale },
    } = props;

    const [currentData, setCurrentData] = useState(data);

    useEffect(() => {
      const updateData = async () => {
        const query = Stack.ContentType(contentType).Entry(uid).language(locale);

        // based on getGatsbyDataFormat: https://github.com/contentstack/live-preview-sdk/blob/0da5d451f668694ad93b39b45e46d45a54e52826/src/contentstack-live-preview-HOC.ts#L102
        const fetchedData = await query.toJSON().fetch();
        const dataTitle = camelCase(`contentstack_${contentType}`);
        const newData = { [dataTitle]: fetchedData };

        setCurrentData({ ...data, newData });
      };

      if (process.env.GATSBY_CONTENTSTACK_LIVE_PREVIEW === 'true') onEntryChange(updateData);
    }, [data, uid, locale]);

    return <WrappedComponent {...{ ...props, data: currentData }} />;
  };

export default withContentstackPreview;
