import { ProductCardProps } from '@design-system/product-card';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';
import {
  ItemListingProductCardCategory,
  ItemListingProductCardRef,
} from 'mappers/itemListing/models';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { ProductCardCategory, ProductCardDataCommon, ProductCardRef } from './models';

const productCardMapper = (
  { heroImage, headline, description_rte, label, fullPath }: ProductCardDataCommon,
  {
    image_settings: imageSettings,
    card_content_ui: headingUi,
    variant,
    hide_labels,
  }:
    | Omit<ItemListingProductCardCategory, 'type' | '__typename'>
    | Omit<ItemListingProductCardRef, 'type' | '__typename'>
): ProductCardProps =>
  ({
    ...(heroImage && {
      cardMedia: {
        image: imageMapper({
          ...{
            alt: heroImage.alt,
            image: {
              gatsbyImageData: heroImage.localAsset.childImageSharp.gatsbyImageData,
              url: heroImage.url,
            },
          },
          ...imageSettings,
        }),
      },
    }),
    link: { component: TMPLinkWrap, props: { href: fullPath } },
    cardContent: {
      headingBlock: headingBlockMapper({
        ui: headingUi,
        headline,
        description: null,
        description_rte,
        link: [],
      }),
    },
    ...(!hide_labels &&
      label[0]?.content && {
        cardLabel: {
          labelContent: label[0].content,
          srOnlyLabelPrefix: label[0].sr_prefix,
          labelVariant: label[0].variant,
          labelSize: label[0].size,
        },
      }),
    variant,
  } as ProductCardProps);

export const productCardCategoryMapper = (
  { productData, label, fullPath }: ProductCardCategory,
  settings: Omit<ItemListingProductCardCategory, 'type' | '__typename'>
): ProductCardProps => {
  const { heroImage, Product_Title } = productData;

  return productCardMapper(
    {
      heroImage,
      headline: Product_Title,
      description_rte: null,
      label,
      fullPath,
    },
    settings
  );
};

export const productCardRefMapper = (
  {
    listing_label: label,
    fields: {
      fullPath,
      productData: { heroImage, Product_Title },
    },
  }: ProductCardRef,
  settings: Omit<ItemListingProductCardRef, 'type' | '__typename'>
): ProductCardProps =>
  productCardMapper(
    {
      heroImage,
      headline: Product_Title,
      description_rte: null,
      label,
      fullPath,
    },
    settings
  );
