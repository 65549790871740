import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import { Icon } from '@design-system/icon';
import { LinkProps } from '@design-system/link';
import classNames from 'classnames';

import '@design-system/link/dist/cjs/index.css';

const TMPLinkWrap = ({
  variant = 'primary',
  size = 'small',
  children,
  className,
  iconPrefix,
  iconSuffix,
  href,
  ...htmlProperties
}: LinkProps): ReactElement => {
  const IconPrefixComponent = iconPrefix?.component || Icon;
  const IconSuffixComponent = iconSuffix?.component || Icon;

  const isInternal = /^\/(?!\/)/.test(href);
  const commonProps = {
    // ref,
    className: classNames(
      'cds-link',
      className,
      `cds-link--${variant}`,
      `cds-link--${variant}-${size}`
    ),
    ...htmlProperties,
  };

  const content = (
    <>
      {iconPrefix?.props && (
        <IconPrefixComponent
          className={classNames('icon__prefix', `icon--${variant}-icon`)}
          {...iconPrefix.props}
        />
      )}
      <span
        className={classNames('cds-link__inner', {
          'cds-sr-only': variant.includes('-icon'),
        })}
      >
        {children}
      </span>
      {iconSuffix?.props && (
        <IconSuffixComponent
          className={classNames('icon__suffix', `icon--${variant}-icon`)}
          {...iconSuffix.props}
        />
      )}
    </>
  );

  return isInternal ? (
    <Link to={href} {...commonProps}>
      {content}
    </Link>
  ) : (
    <a href={href} {...commonProps}>
      {content}
    </a>
  );
};

export default TMPLinkWrap;
