import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import { SeoProps, SeoQuery } from './models';

const Seo: FC<SeoProps> = ({
  lang,
  urls,
  url: seoUrl,
  seo: {
    title,
    description,
    keywords,
    external_hreflangs: externalHreflangs,
    additional_meta: additionalMeta,
    canonical_url: canonicalUrlObj,
    open_graph,
    seoImage,
    pageType = 'website',
  },
  pageUid,
}) => {
  const {
    contentstackHarpicSettings: { site_name, default_locale },
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery<SeoQuery>(graphql`
    query Seo {
      contentstackHarpicSettings {
        site_name
        default_locale
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const { href: canonicalUrl } = canonicalUrlObj;

  const siteTitle = site_name;
  const titleTemplate = title === siteTitle ? '%s' : `%s | ${siteTitle}`;
  const siteLang = lang || default_locale;
  const origin = siteUrl.endsWith('/') ? siteUrl.slice(0, -1) : siteUrl;

  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: open_graph?.title || title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: open_graph?.type || pageType,
    },
    {
      property: 'og:url',
      content: open_graph?.url || `${origin}${seoUrl}`,
    },
    ...(open_graph?.image?.url || seoImage
      ? [
          {
            property: 'og:image',
            content: open_graph?.image?.url || seoImage,
          },
        ]
      : []),
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    ...(keywords?.length
      ? [
          {
            name: 'keywords',
            content: keywords.join(', '),
          },
        ]
      : []),
    ...additionalMeta.map(({ content, name, property }) => ({
      ...(name && { name }),
      ...(property && { property }),
      content,
    })),
  ];

  const link = [
    ...urls.map(({ locale: hreflang, url }) => ({
      rel: 'alternate',
      href: `${siteUrl}${url}`,
      hreflang,
    })),
    ...externalHreflangs.map(({ hreflang, url: { href } }) => ({
      rel: 'alternate',
      href,
      hreflang,
    })),
  ];

  return (
    // TODO: replace helmet with buildin Head feature, to rethink
    <Helmet
      htmlAttributes={{ lang: siteLang, 'data-page-uid': pageUid }}
      {...{ title, titleTemplate, meta, link }}
    >
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
    </Helmet>
  );
};

export default Seo;
